var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"max-width":"900px","scrollable":"","persistent":""}},[_c('ValidationObserver',{ref:"user-form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"max-width":"900px"}},[_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Apellido paterno","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Apellido paterno","error-messages":errors},model:{value:(_vm.editedItem.paternal_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "paternal_name", $$v)},expression:"editedItem.paternal_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Apellido materno","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Apellido Materno","error-messages":errors},model:{value:(_vm.editedItem.maternal_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "maternal_name", $$v)},expression:"editedItem.maternal_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nombre(s)","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre(s)","error-messages":errors},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Correo electrónico","rules":{
                    required: true,
                    email: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo electrónico","error-messages":errors,"type":"email"},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Contraseña","rules":{
                    min: 6,
                    required: _vm.formTitle === 'Añadir Usuario',
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"Contraseña","error-messages":errors,"counter":"6","maxlength":"6"},model:{value:(_vm.editedItem.password),callback:function ($$v) {_vm.$set(_vm.editedItem, "password", $$v)},expression:"editedItem.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Código","rules":{
                    min: 4,
                    required: _vm.formTitle === 'Añadir Usuario',
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Código","error-messages":errors,"counter":"4","maxlength":"4"},model:{value:(_vm.editedItem.code),callback:function ($$v) {_vm.$set(_vm.editedItem, "code", $$v)},expression:"editedItem.code"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.editedItem.birthday,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.editedItem, "birthday", $event)},"update:return-value":function($event){return _vm.$set(_vm.editedItem, "birthday", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"Fecha de nacimiento","rules":{
                        required: true,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de nacimiento","prepend-icon":"mdi-calendar","readonly":"","error-messages":errors},model:{value:(_vm.editedItem.birthday),callback:function ($$v) {_vm.$set(_vm.editedItem, "birthday", $$v)},expression:"editedItem.birthday"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.ModalBirthday),callback:function ($$v) {_vm.ModalBirthday=$$v},expression:"ModalBirthday"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.editedItem.birthday),callback:function ($$v) {_vm.$set(_vm.editedItem, "birthday", $$v)},expression:"editedItem.birthday"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.ModalBirthday = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.editedItem.birthday)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Dirección","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Dirección","error-messages":errors},model:{value:(_vm.editedItem.address),callback:function ($$v) {_vm.$set(_vm.editedItem, "address", $$v)},expression:"editedItem.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":{
                    required: true,
                    digits: 10,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Teléfono","type":"text","error-messages":errors,"counter":"10","maxlength":"10"},model:{value:(_vm.editedItem.phone),callback:function ($$v) {_vm.$set(_vm.editedItem, "phone", $$v)},expression:"editedItem.phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Contacto","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Contacto","error-messages":errors},model:{value:(_vm.editedItem.contact_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "contact_name", $$v)},expression:"editedItem.contact_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Rol","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","label":"Rol","error-messages":errors,"items":_vm.roles},model:{value:(_vm.editedItem.role),callback:function ($$v) {_vm.$set(_vm.editedItem, "role", $$v)},expression:"editedItem.role"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-textarea',{attrs:{"auto-grow":"","dense":"","label":"Comentarios","rows":"1"},model:{value:(_vm.editedItem.comments),callback:function ($$v) {_vm.$set(_vm.editedItem, "comments", $$v)},expression:"editedItem.comments"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('roles-permissions-card',{attrs:{"roles":_vm.roles,"roleSelected":_vm.editedItem.role}})],1)],1)],1)],1),_c('v-divider')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","disabled":_vm.isBtbBlocked || invalid,"text":"","loading":_vm.isBtbBlocked},on:{"click":_vm.submit}},[_vm._v(" Guardar ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }